<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="showCreateUserModal()"
        ><PlusOutlined />新增</a-button
      >
    </div>
    <div class="table-search">
      <a-form
        :model="formState"
        layout="inline"
        autocomplete="off"
        @submit="handleRefresh"
      >
        <a-form-item label="用户名/手机号">
          <a-input
            v-model:value="formState.search_keys"
            placeholder="请输入用户名/手机号"
          />
        </a-form-item>
        <a-form-item label="会员等级">
          <a-select
            placeholder="请选择会员等级"
            v-model:value="formState.user_level"
          >
            <a-select-option :value="0">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in RefLevelList"
              :key="index"
              :value="item.level_id"
              >{{ item.level_name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="注册时间">
          <a-range-picker format="YYYY/MM/DD" v-model:value="formState.betweenTime" />
        </a-form-item>
        <a-form-item class="search-btn">
          <a-button type="primary" html-type="submit">搜索</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-table
      rowKey="menu_id"
      :columns="columns"
      :data-source="data"
      :pagination="Refpagination"
      @change="handleRefresh"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a @click="showUpdateUserModal(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="showDeleteConfirm(record)"
              >删除</a
            >
          </span>
        </template>

        <template v-if="column.dataIndex === 'tags'">
          <span >
            <a-tag
              v-for="tag in record.user_label_name.split(',')"
              :key="tag"
              :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
            >
              {{ tag.toUpperCase() }}
            </a-tag>
          </span>
        </template>
        
      </template>
      
    </a-table>
    <create-user
      ref="RefCreateUser"
      :levelList="RefLevelList"
      :labelList="RefLabelList"
      @handleSubmit="handleRefresh"
    />
    <update-user
      ref="RefUpdateUser"
      :levelList="RefLevelList"
      :labelList="RefLabelList"
      @handleSubmit="handleRefresh"
    />
  </div>
</template>
<script>
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode, defineComponent, reactive, ref, onMounted } from "vue";
import { Modal, message } from "ant-design-vue";
import CreateUser from "./modules/user/CreateUser";
import UpdateUser from "./modules/user/UpdateUser";
import * as Api from "@/addons/user/api";
const columns = [
  {
    title: "UID",
    dataIndex: "uid",
    width: "10%",
  },
  {
    title: "APPID",
    dataIndex: "appid",
    width: "12%",
  },
  {
    title: "用户名",
    dataIndex: "user_name",
    width: "10%",
  },
  {
    title: "用户昵称",
    dataIndex: "nick_name",
    width: "10%",
  },
  {
    title: "手机号",
    dataIndex: "phone",
    width: "10%",
  },
  {
    title: '标签',
    key: 'tags',
    dataIndex: 'tags',
    scopedSlots: { customRender: 'tags' },
  },
  {
    title: "注册时间",
    dataIndex: "create_time",
    width: "16%",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "20%",
  },
];

export default defineComponent({
  components: {
    PlusOutlined,
    CreateUser,
    UpdateUser,
  },
  setup() {
    const formState = reactive({
      search_keys: "",
      user_level: 0,
      betweenTime: "",
    });


    const data = ref([]);
    const Refpagination = ref({});

    // 添加用户
    const RefCreateUser = ref();
    const showCreateUserModal = () => {
      RefCreateUser.value.showFromModal();
    };

    // 编辑用户
    const RefUpdateUser = ref();
    const showUpdateUserModal = (item) => {
      RefUpdateUser.value.showFromModal(item);
    };

    // 刷新用户列表数据
    const handleRefresh = (pagination) => {
      if(typeof pagination !=='undefined'){
        formState.page = pagination.current
      }
      Api.user_list(formState).then((res) => {
        Refpagination.value.current = res.data.list.current_page;
        Refpagination.value.pageSize = res.data.list.per_page;
        Refpagination.value.total = res.data.list.total;
        data.value = res.data.list.data;
      });
    };

    const RefLabelList = ref();
    const getLabelList = () => {
      Api.user_label_all().then((res) => {
        RefLabelList.value = res.data.list;
      });
    };

    // 等级列表
    const RefLevelList = ref();

    // 刷新等级列表数据
    const getLevelList = () => {
      Api.user_level_all().then((res) => {
        RefLevelList.value = res.data.list;
      });
    };

    const showDeleteConfirm = (record) => {
      Modal.confirm({
        title: "确定要删除【" + record.user_name + "】吗?",
        icon: createVNode(ExclamationCircleOutlined),
        content: "删除用户后将无法恢复，请谨慎操作！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          Api.user_del({ id: record.uid }).then((res) => {
            message.success(res.message);
            handleRefresh();
          });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    };

    onMounted(() => {
      handleRefresh();
      getLevelList();
      getLabelList();
    });

    return {
      value1: ref(),
      formState,
      columns,
      data,
      Refpagination,
      RefCreateUser,
      RefUpdateUser,
      showCreateUserModal,
      showUpdateUserModal,
      showDeleteConfirm,
      handleRefresh,
      RefLevelList,
      RefLabelList,
    };
  },
});
</script>